import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCLDDrh_NOAbrEevAabohGveDjaGcp1CY8',
  authDomain: 'codex-icincodejunio.firebaseapp.com',
  projectId: 'codex-icincodejunio',
  storageBucket: 'codex-icincodejunio.appspot.com',
  messagingSenderId: '408623543516',
  appId: '1:408623543516:web:420322b312fe9d10af3019',
  measurementId: 'G-HF4C7D2XEQ',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
